// carousel

.carousel {
  outline: none;
}

// carousel fade animation
.carousel-fade {
  .carousel-item {
    transition: opacity 1.7s ease !important;
    position: absolute !important;
    display: block !important;
    opacity: 0;
  }

  .carousel-item.active {
    position: relative !important;
    opacity: 1;
  }
}
