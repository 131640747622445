// list-group

// list group with nav
[ngbNav] {
  &.list-group {
    li {
      &:first-child {
        .list-group-item {
          border-radius: 0;
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem;
        }
      }
      .list-group-item {
        border-radius: 0;
      }
      &:last-child {
        .list-group-item {
          border-radius: 0;
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
      }
    }
  }
}
